import "jquery-mask-plugin";

import {
  CardIcon,
  CardTitle,
  CheckMark,
  CheckoutCardContent,
  CheckoutCardHead,
  CheckoutCardStyled,
  CheckoutCardWrapperContent,
  ContentColumn,
  CupomBlock,
  CustomError,
  DrawerContent,
  FieldWrapper,
  FormCheckout,
  FormConfirmAccount,
  FormFilters,
  FormTextField,
  FormTitle,
  InputRadio,
  Overlay,
  PageHead,
  PageHeadActions,
  PageSubtitle,
  PageTitle,
  PaymentOptionStyled,
  PaymentOptionTitle,
  PaymentTypeIcon,
  WrapApplyFilter,
  WrapCheckoutButton,
  WrapContinueButton,
  WrapProducts,
  WrappLoadMore,
  WrapperContentColumn,
  WrapperFormConfirmAccount,
  WrapperFormPersonalData,
  WrapperHead,
  WrapperPage,
  CountCartItens,
  WrapExternalLink,
  WrapDiscountValue,
  FormTryAgainNF,
  MessageToast,
  ToastTitle,
} from "./styles";

import { HistoryCategory, HistoryItem, MenuCategory, MenuCategoryItem } from "./components/Categories";
import { OrderDetail, SkippedStitches } from "./components/order-details";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles'

import { Grid, InputAdornment, Select, TextField, useMediaQuery, } from "@material-ui/core";
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';

import $ from 'jquery';
import AddressService from '../../../services/Address';
import AnuncioService from '../../../services/Anuncios';
import PlugNotas from '../../../services/PlugNotas';

import Button from "../../../components/Form/button";
import CartService from '../../../services/Cart';
import Categorizacao from '../../../services/Categorizacao';
import CouponsService from '../../../services/Coupons';
import Domain from '../../../services/Domain';
import Drawer from '@material-ui/core/Drawer';
import { DrawerHeader } from "./components/Drawer";
import FormControl from "@material-ui/core/FormControl";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import InputLabel from "@material-ui/core/InputLabel";
import ListBlockItem from '../../../components/Display/list/block';
import ListMarkup from '../../../components/Display/list/base';
import ModalDialog from '../../../components/ModalDialog';
import OrderService from '../../../services/Order';
import CancelOrders from '../../../services/CancelOrders';
import PricePoints from "../../../components/Display/price/points";
import PriceReal from "../../../components/Display/price/default";
import ProductBox from '../../../components/Layout/product-box';
import ProductInline from '../../../components/Layout/product-inline';
import { HistoryFilters, RemoveFilter } from '../../../components/Filters';

import downloadBase64ToPDF from './../../../utils/downloadBase64ToPDF';

import Swal from 'sweetalert2';
import SweetAlert from 'sweetalert';

import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons";
import { useForm } from 'react-hook-form';
import { useLocation } from "react-router-dom";
import CheckoutThanks, { HText } from "./components/Success";
import QrReader from 'react-qr-reader';

const FieldError = ({ children }) => <CustomError> <Icon icon={["fas", "level-up"]} /> {children} </CustomError>

const PaymentOption = ({ title, icon, children }) => {

  return (
    <PaymentOptionStyled>
      <WrapperContentColumn>
        {children}

        <ContentColumn className="ContentColumn">
          <PaymentTypeIcon>
            {icon}
          </PaymentTypeIcon>
          <PaymentOptionTitle>
            {title}
          </PaymentOptionTitle>
        </ContentColumn>

        <CheckMark className="CheckMark">
          <Icon icon={["fas", "check"]} />
        </CheckMark>
        <Overlay className="Overlay" />
      </WrapperContentColumn>
    </PaymentOptionStyled>
  )
}

const CheckoutCard = ({ icon, title, children }) => {

  return (
    <CheckoutCardStyled>
      <CheckoutCardHead>
        <CardIcon>
          {icon}
        </CardIcon>
        <CardTitle>
          {title}
        </CardTitle>
      </CheckoutCardHead>

      <CheckoutCardContent>
        {children}
      </CheckoutCardContent>

    </CheckoutCardStyled>
  )
}

const ListSize = ({ title, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={` ${open ? "wrap-size active-size" : "wrap-size no-active"}`}>
      <button type="button" className="btn-filter-size list-size-item" onClick={() => setOpen(!open)}>
        <h2 className="_filter-title ">{title}</h2>
        <div className="chevron-icon">
          <Icon icon={faChevronRight} />
        </div>
      </button>

      {open && (
        <button type="button" className="btn-filter-size back-list-sizes" onClick={() => setOpen(!open)}>
          <div className="chevron-icon">
            <Icon icon={faChevronLeft} />
          </div>
          <h2 className="_filter-title ">{title}</h2>
        </button>
      )}

      {open && (
        <>
          {children}
        </>
      )}
    </div>
  )
}

const ListSizePanel = ({ children }) => {

  return (
    <div className="size-panel">
      {children}
    </div>
  )
}

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }
}));

const CupomField = withStyles({
  root: {
    "& .MuiFormLabel-root.Mui-error": {
      color: "green !important"
    },

    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "green !important",
      color: "green !important"
    },

    "& .MuiInputBase-input": {
      color: "#14233e !important",
      fontWeight: "bold"
    },

    "& .MuiInputBase-input:disabled": {
      color: "#14233e !important",
      fontWeight: "bold"
    },
  },
})(TextField);

const Checkout = () => {
  const steps = ['Validar conta', 'Checkout', 'Finalizar'];

  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const cartService = new CartService();
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  // * Refs Dialog
  const refConfirmAccount = useRef();
  const refCheckout = useRef();
  const refReadQrCode = useRef();

  const [category, setCategory] = useState("");
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState({ nome: "" });
  const [conditions, setConditions] = useState([]);
  const [condition, setCondition] = useState({ nome: "" });
  const [listSelectedCategorization, setListSelectedCategorization] = useState([]);
  const [listActiveCategorization, setListActiveCategorization] = useState([]);
  const [allCategorizationLabel, setAllCategorizationLabel] = useState("");
  const [listCategorization, setListCategorization] = useState([]);
  const [searchAds, setSearchAds] = useState();
  const [limit, setLimit] = useState(8);
  const [searchKey, setSearchKey] = useState("");
  const [deliveryType, setDeliveryType] = useState();
  const [points, setPoints] = useState();
  const [price, setPrice] = useState();
  const [cart, setCart] = useState(null);
  const [currentBalance, setCurrentBalance] = useState(null);
  const [cep, setCep] = useState("");
  const [coupon, setCoupon] = useState({ coupon_code: "", _id: "", show: false });
  const [couponApplied, setCouponApplied] = useState(false);
  const [typePurchase, setTypePurchase] = useState("");
  const [paymentLink, setPaymentLink] = useState("");
  const [confirmPayment, setConfirmPayment] = useState(false);
  const [checkLastStep, setCheckLastStep] = useState(false);
  const [checkFinishtStep, setCheckFinishStep] = useState(false);
  const [listActiveSizes, setlistActiveSizes] = useState();
  const [activeSize, setActiveSize] = useState();
  const [checkIsNewScamber, setCheckIsNewScamber] = useState(false);
  const [formTryAgainNf, setFormTryAgainNf] = useState(true);
  const [invoiceGenerated, setInvoiceGenerated] = useState(false);
  const [generateInvoice, setGenerateInvoice] = useState(false);
  const [resendGenerateInvoice, setResendGenerateInvoice] = useState(false);
  const [plugnotasReturn, setPlugnotasReturn] = useState([{ message: "", type: "", invoiceId: "" }]);
  const [consultPlugNotasButton, setConsultPlugNotasButton] = useState(false);
  const [saveFormValues, setSaveFormValues] = useState({
    user_adress: "",
    user_cep: "",
    user_city: "",
    user_complement: "",
    user_cpf: "",
    user_district: "",
    user_email: "",
    user_full_name: "",
    user_number: "",
    user_phone: "",
    user_state: ""
  });



  const [isOnQRCodeReader, setIsOnQRCodeReader] = useState(false);
  const [lastQrCodeProduct, setLastQrCodeProduct] = useState();


  // * Steps Wizard States
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const [paymentMethod, setPaymentMethod] = useState('payment_machine');
  const [createdOrder, setCreatedOrder] = useState();

  // * Drawers States
  const [drawerFilter, setDrawerFilter] = useState(false);
  const [drawerCart, setDrawerCart] = useState(false);
  const [drawerCategories, setDrawerCategories] = useState(false);
  const [drawerSize, setDrawerSize] = useState(false);

  // * Handle Drawers
  const handleDrawerFilter = () => setDrawerFilter(!drawerFilter);
  const handleDrawerCart = () => setDrawerCart(!drawerCart);
  const handleDrawerCategories = () => {
    setDrawerCategories(!drawerCategories)
    onSelectCategorization();
  };

  const handleDrawerSizes = () => {
    setDrawerSize(!drawerSize)
    // onSelectCategorization();
  };

  // * Handle Dialogs
  const handleDialogConfirmAccount = () => {
    setDrawerCart(dwCart => !dwCart)

    setTimeout(() => refConfirmAccount.current.openModal(), 400);
  }

  // * Handle Steps Wizard
  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // setSkipped(newSkipped);

    if (activeStep === 3) {
      setSkipped(newSkipped);

    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const { handleSubmit, register, errors, reset, setValue } = useForm();
  const { handleSubmit: handleSubmitUserEmail, register: registerUserEmailField, errors: errorsUserEmail, setValue: setValueUserEmailField, } = useForm({ mode: "onChange" });
  const { handleSubmit: handleSubmitCheckout, register: registerCheckoutField, errors: errorsCheckout, setValue: setValueCheckoutField } = useForm();
  const { handleSubmit: handleSubmitFormTryAgainNf, register: registerFormTryAgainNf, errors: errorsFormTryAgainNf, setValue: setValueFormTryAgainNf } = useForm({ mode: "onChange" });

  const loadInfo = () => {
    const categorization = new Categorizacao();
    const domain = new Domain();

    categorization
      .list()
      .then((response) => {
        if (response.status === 200) {
          setListCategorization(response.data.payload);
          setListActiveCategorization(response.data.payload);
        }
      })
      .catch((error) =>
        categorization.formatErrorMessage(error.response, 'CALICT'),
      );

    domain
      .listBrands()
      .then((response) => {
        if (response.status === 200) {
          setBrands(response.data.payload);
        }
      })
      .catch((error) =>
        domain.formatErrorMessage(error.response, 'CALIBDO'),
      );

    domain
      .listConditions()
      .then((response) => {
        if (response.status === 200) {
          setConditions(response.data.payload);
        }
      })
      .catch((error) =>
        domain.formatErrorMessage(error.response, 'CALICDO'),
      );

    domain.listSizeGroups('scamb').then((response) => {
      if (response.status === 200) {
        setlistActiveSizes(response.data.payload);
      }
    }).catch((error) =>
      this.domain.formatErrorMessage(error.response, 'RLISG')
    )
  };

  const onSelectCategorization = (categorizationlist) => {
    let selectedCategorizations = categorizationlist
      ? categorizationlist
      : listSelectedCategorization;

    let categorizationIdsSelected = selectedCategorizations.map(
      (selectedCategorization) => {
        return selectedCategorization.categorization._id;
      }
    );
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'start'
  });

  const handleCondition = (e, condition) => {
    setCondition(condition);
  };

  const handleBrand = (e, brand) => {
    setBrand(brand);
  };

  const handleCategorizationBack = (oldCategorizationList, index) => {
    const listSelected = listSelectedCategorization.slice(0, index);
    setListSelectedCategorization(listSelected);

    setListActiveCategorization(oldCategorizationList);
  };

  const handleCategorization = (categorization) => {
    setCategory(categorization.nome);

    let listSelected;
    if (categorization.categorias && categorization.categorias.length > 0) {
      setListActiveCategorization(categorization.categorias);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else if (
      categorization.tipos_produtos &&
      categorization.tipos_produtos.length > 0
    ) {
      setListActiveCategorization(categorization.tipos_produtos);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else if (categorization.produtos && categorization.produtos.length > 0) {
      setListActiveCategorization(categorization.produtos);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else {
      listSelected = listSelectedCategorization;
      const lastIndex = listSelected.length > 0 ? listSelected.length - 1 : 0;

      if (listSelected[lastIndex] && listSelected[lastIndex].lastLevel) {
        listSelected[lastIndex] = {
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
          lastLevel: true,
        };
        setListSelectedCategorization(listSelected);
      } else {
        listSelected.push({
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
          lastLevel: true,
        });
        setListSelectedCategorization(listSelected);
      }
    }
  };

  const searchAnuncio = ({ limit, key, deliveryType, points, price, clear }) => {
    const anuncioService = new AnuncioService();
    const adId = search.get('id');

    if (key) {
      if (key.indexOf('&') !== -1) key = key.replace(/&/g, 'ampersand');
    }

    anuncioService.findAdsByUserSearch({
      searchString: key,
      limit: limit,
      brand: !clear && brand ? brand._id : null,
      category: !clear && listSelectedCategorization.length > 0 ? listSelectedCategorization[listSelectedCategorization.length - 1].categorization._id : null,
      condition: !clear && condition ? condition._id : null,
      deliveryType: deliveryType ? deliveryType : null,
      points: points ? points : null,
      price: price ? price : null,
      storeUrl: 'scambfortaleza',
      website: 'scamb',
      adId: !clear && adId ? adId : undefined,
      size: !clear && activeSize ? activeSize._id : null,
    }).then(response => {
      setSearchAds(response.data.payload.ad);
    }).catch(err => {
      Swal.fire({
        type: "info",
        html: anuncioService.formatErrorMessage(err.response),
        title: "",
      });
    })
  }

  const loadMoreAds = () => {
    const newLimit = limit + 8;
    setLimit(newLimit);

    searchAnuncio({
      limit: newLimit,
      key: searchKey,
      deliveryType: deliveryType ? deliveryType : null,
      points: points ? points : null,
      price: price ? price : null,
    });
  }

  const onSearch = (data) => {
    setSearchKey(data.filterby);
    setDeliveryType(data.frete_type);
    setPoints(`${data.pontosMinimos}-${data.pontosMaximos}`);
    setPrice(data.order);

    searchAnuncio({
      limit: limit,
      key: data.filterby,
      deliveryType: data.frete_type,
      points: `${data.pontosMinimos}-${data.pontosMaximos}`,
      price: data.order,
    });

    handleDrawerFilter();
  }

  const onCleanFilter = () => {
    reset({})
    setBrand([]);
    setCondition([]);
    setListSelectedCategorization([]);
    setLimit(8);
    setSearchKey("");
    setDeliveryType();
    setPoints();
    setPrice();
    setActiveSize();

    search.delete('id');
    searchAnuncio({ limit: 8, key: "", clear: true });

    handleDrawerFilter();
  }

  const addAnuncios = ({ anuncio, callback }) => {

    cartService.insertItenCart({ adId: anuncio }).then(response => {
      if (response.data.type === 'success') {
        setCart(response.data.payload);
        if (callback) callback(true);
      }
    }).catch(err => {
      if (callback) callback(false, err.response);
      Swal.fire({
        type: "info",
        html: cartService.formatErrorMessage(err.response),
        title: "",
      });
    })
  }

  const removeAnuncios = ({ anuncio }) => {

    cartService.deleteItemCart({ adId: anuncio }).then(response => {
      if (response.data.type === 'success') {
        setCart(response.data.payload);
      }
    }).catch(err => {
      Swal.fire({
        type: "info",
        html: cartService.formatErrorMessage(err.response),
        title: "",
      });
    })
  }

  const handleFormCheckout = async (data) => {
    SweetAlert({
      title: "Finalizar Pedido",
      text: `Seu pedido está sendo finalizado, confirmar ação ?`,
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancelar", "Confirmar"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        const orderService = new OrderService();

        setTypePurchase((type) => type = data.payment_option);

        Swal.fire('Criando Pedido... Aguarde');
        Swal.showLoading();
        orderService.createOrder({ scamberBody: data, paymentMethod: paymentMethod }).then(response => {
          Swal.fire({
            title: "Pedido Cadastrado",
            text: response.data.message ? response.data.message : 'Pedido incluido com sucesso!',
            icon: "success",
          });

          handleSearchCart();
          searchAnuncio({ limit: limit, key: "" });

          setValueUserEmailField("user_email", undefined);
          setValueCheckoutField("user_email", undefined);
          setValueCheckoutField("user_full_name", undefined);
          setValueCheckoutField("user_cpf", undefined);
          setValueCheckoutField("user_phone", undefined);

          setSaveFormValues([{

            user_adress: data.user_adress,
            user_cep: data.user_cep,
            user_city: data.user_city,
            user_complement: data.user_complement,
            user_cpf: data.user_cpf,
            user_district: data.user_district,
            user_email: data.user_email,
            user_full_name: data.user_full_name,
            user_number: data.user_number,
            user_phone: data.user_phone,
            user_state: data.user_state
          }])


          if (response.data.payload) {
            setCreatedOrder(response.data.payload.order);
            setPaymentLink(response.data.payload.link_pagamento);
            setCheckLastStep(lastStep => lastStep = true);
            setCheckFinishStep(finishStep => finishStep = true);
            handleNext();
          }

          if (checkIsNewScamber) {
            setValueFormTryAgainNf("user_adress", data.user_adress);
            setValueFormTryAgainNf("user_cep", data.user_cep);
            setValueFormTryAgainNf("user_city", data.user_city);
            setValueFormTryAgainNf("user_complement", data.user_complement);
            setValueFormTryAgainNf("user_cpf", data.user_cpf);
            setValueFormTryAgainNf("user_district", data.user_district);
            setValueFormTryAgainNf("user_email", data.user_email);
            setValueFormTryAgainNf("user_full_name", data.user_full_name);
            setValueFormTryAgainNf("user_number", data.user_number);
            setValueFormTryAgainNf("user_phone", data.user_phone);
            setValueFormTryAgainNf("user_state", data.user_state);
          }

          // refConfirmAccount.current.closeModal();
          // refCheckout.current.closeModal();
        }).catch(err => {
          Swal.fire({
            type: "info",
            html: orderService.formatErrorMessage(err.response),
            title: "",
          });
        })
      }
    });
  };

  const getPhone = ({ phone }) => {
    return phone.ddd + " " + phone.numero
  }

  const findAddressByCEP = () => {
    const addressService = new AddressService();

    if (cep.length === 9) {
      addressService
        .findCep(cep)
        .then((address) => {

          if (address.data) {
            setValueCheckoutField("user_cep", address.data.cep);
            setValueCheckoutField("user_state", address.data.uf);
            setValueCheckoutField("user_city", address.data.localidade);
            setValueCheckoutField("user_adress", address.data.logradouro);
            setValueCheckoutField("user_district", address.data.bairro);
            setValueCheckoutField("user_complement", address.data.complemento);
          }
        })
        .catch(() =>
          Swal.fire("info", "Não foi possível consultar o endereço", "")
        );
    }
  };

  const handleFormUserEmail = async (data) => {
    const userDataEmail = await data.user_email.toLowerCase();

    cartService.checkScamberBuyerAndCartMissingValue({ scamberEmail: userDataEmail })
      .then(response => {
        if (response.data.type === 'success') {
          const data = response.data.payload;
          setCart(data.cart);

          if (data.scamber) {
            setValueCheckoutField("user_full_name", data.scamber.nome);
            setValueCheckoutField("user_cpf", data.scamber.cpf);
            setValueCheckoutField("user_phone", getPhone({ phone: data.scamber.telefone[0] }));
            setCurrentBalance(data.scamber.currentBalance);

            setCheckIsNewScamber((isNewScamber) => isNewScamber = false);

            if (data.scamber.address) {
              setValueCheckoutField("user_cep", data.scamber.address.cep);
              setValueCheckoutField("user_state", data.scamber.address.estado);
              setValueCheckoutField("user_city", data.scamber.address.cidade);
              setValueCheckoutField("user_adress", data.scamber.address.logradouro);
              setValueCheckoutField("user_number", data.scamber.address.numero);
              setValueCheckoutField("user_district", data.scamber.address.bairro);
              setValueCheckoutField("user_complement", data.scamber.address.complemento);

              setSaveFormValues([{
                user_adress: data.scamber.address.logradouro,
                user_cep: data.scamber.address.cep,
                user_city: data.scamber.address.cidade,
                user_complement: data.user_complement,
                user_cpf: data.scamber.cpf,
                user_district: data.scamber.address.bairro,
                user_email: userDataEmail,
                user_full_name: data.scamber.nome,
                user_number: data.scamber.address.numero,
                user_phone: getPhone({ phone: data.scamber.telefone[0] }),
                user_state: data.scamber.address.estado
              }]);

            }

          } else {
            setValueCheckoutField("user_full_name", undefined);
            setValueCheckoutField("user_cpf", undefined);
            setValueCheckoutField("user_phone", undefined);
            setValueCheckoutField("user_cep", undefined);
            setValueCheckoutField("user_state", undefined);
            setValueCheckoutField("user_city", undefined);
            setValueCheckoutField("user_adress", undefined);
            setValueCheckoutField("user_number", undefined);
            setValueCheckoutField("user_district", undefined);
            setValueCheckoutField("user_complement", undefined);
            setCurrentBalance(null);

            setCheckIsNewScamber((isNewScamber) => isNewScamber = true);
          }

          setValueCheckoutField("user_email", userDataEmail);
          setValueFormTryAgainNf("user_email", userDataEmail);
          // refCheckout.current.openModal();
          handleNext();
        }
      }).catch(err => {
        Swal.fire({
          type: "info",
          html: cartService.formatErrorMessage(err.response),
          title: "",
        });
      });


  };

  const consultInvoicePdf = ({ invoiceId }) => {
    const plugNotas = new PlugNotas();

    Swal.fire('Buscando pdf da NFe... Aguarde');
    Swal.showLoading();
    plugNotas.consultInvoicePdf({ idNota: invoiceId }).then((response) => {
      downloadBase64ToPDF(response.data, 'nfe')
      Swal.close();
    }).catch(error => {
      Swal.close();
      Swal.fire({
        type: "info",
        html: plugNotas.formatErrorMessage(error.response),
        title: "",
      });
    });
  }

  const handleAddMoreProducts = async () => {
    await refConfirmAccount.current.closeModal();
    await refCheckout.current.closeModal();
  }

  const calcPrice = ({ points }) => {
    return (parseFloat(points) / 1.818).toFixed(2);
  }

  const calcPriceAllProducts = ({ allProdcuts }) => {
    let total = 0;

    allProdcuts.map(product => {
      return total += Number(calcPrice({ points: product.pontos_por.$numberDecimal }))
    });

    return parseFloat(total).toFixed(2);
  }

  const handleItensAlredyInCart = ({ item }) => {
    const alredyInCart = cart
      ? cart.itens.find(ad => ad._id === item)
      : undefined;

    return alredyInCart;
  }

  const handleCoupomCode = (couponsArray) => {
    const couponService = new CouponsService();

    couponService.findCoupons(couponsArray, 'scamb').then(response => {
      const data = response.data.payload;

      if (response.data.type === 'success' && data) {
        setCoupon({
          coupon_code: data.cupom_code,
          _id: data._id
        });

        setCouponApplied(couponApplied => couponApplied = true);
      };
    })
  }

  const handleSearchCart = useCallback(() => {

    cartService.findCart().then((response) => {
      if (response.data.type === 'success') {
        setCart(response.data.payload);

        if (response.data.payload && response.data.payload.couponUtilizado) {
          handleCoupomCode(response.data.payload.couponUtilizado);
        }
      }
    })
  }, []);

  const handleScan = (data) => {
    if (data) {
      const [url, produto] = data.split('=');
      const anuncioService = new AnuncioService();

      if (produto && (produto !== lastQrCodeProduct)) {
        setLastQrCodeProduct(produto);
        addAnuncios({
          anuncio: produto, callback: (isAdded, errorResponse) => {
            if (isAdded) {
              Swal.fire({ type: 'success', text: 'Produto adicionado ao carrinho com sucesso' })
            } else if (produto !== lastQrCodeProduct) {
              Swal.fire({
                type: "info",
                html: anuncioService.formatErrorMessage(errorResponse, 'ADDQRCP'),
                title: "",
              });
            }
          }
        });
      }
    }
  }

  const handleError = () => {
    Swal.fire({ type: 'info', text: 'QR Code inválido' })
  }

  const handleQrCode = () => {
    refReadQrCode.current.openModal();
    setIsOnQRCodeReader(true);
  }

  const handleCloseQrCode = () => {
    refReadQrCode.current.closeModal();
    setIsOnQRCodeReader(false);
    setLastQrCodeProduct("");
  }

  const handleInsertCoupom = () => {
    const couponService = new CouponsService();

    Swal.fire('Incluindo Cupon... Aguarde!');
    Swal.showLoading();

    couponService.validateCouponCodeAndUpdataCart({
      couponCode: coupon.coupon_code,
      cartId: cart._id,
      website: 'scamb'
    }).then((response) => {
      Swal.close();
      if (response.data.type === 'success') {
        const data = response.data.payload;

        Swal.fire({
          title: "Cupom aplicado com sucesso!",
          icon: "success",
          buttons: 'Ok',
          dangerMode: true
        });
        setCouponApplied(couponApplied => couponApplied = true);
        setCart(data.cart);
        setCoupon({ coupon_code: data.coupon.cupom_code, _id: data.coupon._id, show: true });
      }
    }).catch((error) => {
      Swal.fire({
        type: "info",
        html: couponService.formatErrorMessage(error.response, 'SCINC'),
        title: "",
      });
    });
  }

  const handleDeleteCoupon = () => {
    const couponService = new CouponsService();
    couponService.deleteCoupon(cart._id, coupon._id).then((response) => {

      if (response.data.type === 'success') {

        Swal.fire({
          title: "Cupom deletado com sucesso!",
          icon: "success",
          buttons: 'Ok',
          dangerMode: true
        });

        setCouponApplied(couponApplied => couponApplied = false);
        setCart(response.data.payload);
        setCoupon({ coupon_code: "", _id: "", show: false });
      }
    })
      .catch((error) => {
        Swal.fire({
          type: "info",
          html: couponService.formatErrorMessage(error.response, 'SCINC'),
          title: "",
        });
      })

  }

  useEffect(() => {
    searchAnuncio({ limit: limit, key: "" });
    loadInfo();
    handleSearchCart();
  }, []);

  useEffect(() => {
    $('.user_cpf').mask('000.000.000-00', { reverse: true });
    $('.user_phone').mask('(00) 00000-0000');
    $('.user_cep').mask('00000-000');
  });

  useEffect(() => {
    setAllCategorizationLabel(
      listSelectedCategorization.reduce((label, selected, index) => {
        return (label += ` ${index > 0 ? ">" : ""} ${selected.categorization.nome
          }`);
      }, "")
    );
  }, [
    listSelectedCategorization.length,
    listSelectedCategorization[listSelectedCategorization.length - 1],
  ]);

  const handleFinishWizard = async () => {


    SweetAlert({
      title: "Finalizar Pedido",
      text: `Você esta finalizando o pedido. \n Deseja confirmar esta ação?`,
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancelar", "Confirmar"],
    }).then(async (isConfirm) => {
      if (isConfirm) {
        await refConfirmAccount.current.closeModal();
        setInvoiceGenerated(status => status = false);
        setGenerateInvoice(status => status = false);
        setResendGenerateInvoice(status => status = false);
        handleReset();
        setValueUserEmailField("user_email", "");

        return;
      }
    })
  }

  const cancelOrder = () => {
    SweetAlert({
      title: "Cancelar Pedido",
      text: `Seu pedido está sendo cancelado, confirmar ação ?`,
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancelar", "Confirmar"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        Swal.fire('Efetuando o cancelamento... Aguarde');
        Swal.showLoading();
        const cancelOrderService = new CancelOrders();

        if (createdOrder) {
          cancelOrderService.reversalOrder(createdOrder._id).then((response) => {
            if (response.data && response.data.type === 'success') {
              Swal.fire({ type: "success", html: 'Pedido cancelado com sucesso!', title: "" });
              handleReset();
              setValueUserEmailField("user_email", "");

              setTimeout(() => {
                refConfirmAccount.current.closeModal();
              }, 500);

            } else {
              Swal.fire({
                type: "info",
                html: response.data.message,
                title: "",
              });
            }
          }).catch((error) => {
            Swal.fire({
              type: "info",
              html: cancelOrderService.formatErrorMessage(error.response, 'FNLOD'),
              title: "",
            });
          })
        } else {
          Swal('Pedido não encontrado para cancelamento');
        }
      }
    });
  }

  const finishOrder = () => {
    SweetAlert({
      title: "Confirmar Pagamento",
      text: `Seu pedido está sendo confirmado, confirmar ação ?`,
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancelar", "Confirmar"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        Swal.fire('Efetuando a confirmação do pagamento... Aguarde');
        Swal.showLoading();

        const orderService = new OrderService();
        if (createdOrder) {
          orderService.orderFinish({ order: createdOrder._id }).then((response) => {
            if (response.data && response.data.type === 'success') {
              Swal.fire({ type: "success", html: 'Pagamento confirmado com sucesso!', title: "" });

              setConfirmPayment((payment) => payment = true);
              setCheckFinishStep(finishStep => finishStep = false);

            } else {
              Swal.fire({
                type: "info",
                html: response.data.message,
                title: "",
              });
            }
          }).catch((error) => {
            Swal.fire({
              type: "info",
              html: orderService.formatErrorMessage(error.response, 'FNLOD'),
              title: "",
            });
          })
        } else {
          Swal.fire('Pedido não encontrado para confirmação de pagamento');
        }
      }
    });
  }

  const handlePlugNotas = () => {

    const nota = new PlugNotas();

    if (createdOrder) {

      nota.generateSimpleInvoice(createdOrder._id).then((response) => {

        window.open('about:blank', "_blank").document.write(response.data);


      }).catch((error) => {
        Swal.fire({
          type: "info",
          html: nota.formatErrorMessage(error.response, 'FNLOD'),
          title: "",
        });
      })
    } else {
      Swal.fire('Pedido não encontrado para gerar recibo!');
    }
  }

  const handleCreateInvoice = async () => {
    const nota = new PlugNotas();

    if (createdOrder) {

      try {
        Swal.fire('Gerando Nota Fiscal... Aguarde');
        Swal.showLoading();

        const response = await nota.createInvoice({ orderId: createdOrder._id });

        if (response.status === 200) {
          const data = await response.data;
          setGenerateInvoice(generated => generated = true);

          setPlugnotasReturn([{ message: data.message, type: data.title }]);

          switch (data.title) {
            case "REJEITADO":
              console.log('__INVOICE REJECTED: ', data);
              setInvoiceGenerated(generated => generated = false);
              setResendGenerateInvoice(generated => generated = true);
              Swal.close();
              break;
            case "DENEGADO":
              console.log('__INVOICE DENEGADO: ', data);
              setInvoiceGenerated(generated => generated = false);
              setResendGenerateInvoice(generated => generated = true);
              Swal.close();
              break;
            case "CANCELADO":
              console.log('__INVOICE CANCELADO: ', data);
              setInvoiceGenerated(generated => generated = false);
              setResendGenerateInvoice(generated => generated = true);
              Swal.close();
              break;
            case "CONCLUIDO":
              console.log('__INVOICE SUCCESS: ', data);
              setInvoiceGenerated(generated => generated = true);
              setResendGenerateInvoice(generated => generated = false);
              setPlugnotasReturn([{ message: data.message, type: data.title, invoiceId: data.payload.id }]);
              Swal.close();
              break;
            default:
              setPlugnotasReturn([{ message: data.message }]);
              setConsultPlugNotasButton(status => status = true);
              Swal.close();
          }

          setValueFormTryAgainNf("user_email", saveFormValues[0].user_email);
          setValueFormTryAgainNf("user_full_name", saveFormValues[0].user_full_name);
          setValueFormTryAgainNf("user_cpf", saveFormValues[0].user_cpf);
          setValueFormTryAgainNf("user_phone", saveFormValues[0].user_phone);
          setValueFormTryAgainNf("user_cep", saveFormValues[0].user_cep);
          setValueFormTryAgainNf("user_state", saveFormValues[0].user_state);
          setValueFormTryAgainNf("user_city", saveFormValues[0].user_city);
          setValueFormTryAgainNf("user_adress", saveFormValues[0].user_adress);
          setValueFormTryAgainNf("user_number", saveFormValues[0].user_number);
          setValueFormTryAgainNf("user_district", saveFormValues[0].user_district);
          setValueFormTryAgainNf("user_complement", saveFormValues[0].user_complement);
          console.log("__USER DATA: ", saveFormValues[0]);

          // setSaveFormValues([{
          //   user_adress: data.scamber.address.logradouro,
          //   user_cep: data.scamber.address.cep,
          //   user_city: data.scamber.address.cidade,
          //   user_complement: data.user_complement,
          //   user_cpf: data.scamber.cpf,
          //   user_district: data.scamber.address.bairro,
          //   user_email: userDataEmail,
          //   user_full_name: data.scamber.nome,
          //   user_number: data.scamber.address.numero,
          //   user_phone: getPhone({ phone: data.scamber.telefone[0] }),
          //   user_state: data.scamber.address.estado
          // }]);
        }

      } catch (error) {
        Swal.fire({
          type: "info",
          html: nota.formatErrorMessage(error.response, 'FNLOD'),
          title: "",
        });
      }

    }

  }

  const checkInvoice = async () => {

    const nota = new PlugNotas();

    if (createdOrder) {

      try {
        Swal.fire('Consultando Nota Fiscal... Aguarde');
        Swal.showLoading();

        const response = await nota.consultInvoice({ orderId: createdOrder._id });

        if (response.status === 200) {
          const data = await response.data;
          setGenerateInvoice(generated => generated = true);

          setPlugnotasReturn([{ message: data.message, type: data.title }]);

          switch (data.title) {
            case "REJEITADO":
              console.log('__INVOICE REJECTED: ', data);
              setInvoiceGenerated(generated => generated = false);
              setResendGenerateInvoice(generated => generated = true);
              Swal.close();
              break;
            case "DENEGADO":
              console.log('__INVOICE DENEGADO: ', data);
              setInvoiceGenerated(generated => generated = false);
              setResendGenerateInvoice(generated => generated = true);
              Swal.close();
              break;
            case "CANCELADO":
              console.log('__INVOICE CANCELADO: ', data);
              setInvoiceGenerated(generated => generated = false);
              setResendGenerateInvoice(generated => generated = true);
              Swal.close();
              break;
            case "CONCLUIDO":
              console.log('__INVOICE SUCCESS: ', data);
              setInvoiceGenerated(generated => generated = true);
              setResendGenerateInvoice(generated => generated = false);
              setPlugnotasReturn([{ message: data.message, type: data.title, invoiceId: data.payload.id }]);
              Swal.close();
              break;
            default:
              setPlugnotasReturn([{ message: data.message }]);
              setConsultPlugNotasButton(status => status = true);
          }
        }

      } catch (error) {
        Swal.fire({
          type: "info",
          html: nota.formatErrorMessage(error.response, 'FNLOD'),
          title: "",
        });
      }

    }


  }

  const handleFormTryAgainNf = async (data) => {

    setValueFormTryAgainNf("user_email", data.user_email);
    setValueFormTryAgainNf("user_full_name", data.user_full_name);
    setValueFormTryAgainNf("user_cpf", data.user_cpf);
    setValueFormTryAgainNf("user_phone", data.user_phone);
    setValueFormTryAgainNf("user_cep", data.user_cep);
    setValueFormTryAgainNf("user_state", data.user_state);
    setValueFormTryAgainNf("user_city", data.user_city);
    setValueFormTryAgainNf("user_adress", data.user_adress);
    setValueFormTryAgainNf("user_number", data.user_number);
    setValueFormTryAgainNf("user_district", data.user_district);
    setValueFormTryAgainNf("user_complement", data.user_complement);

    const nota = new PlugNotas();

    if (createdOrder) {

      try {

        Swal.fire('Gerando Nota Fiscal... Aguarde');
        Swal.showLoading();

        const response = await nota.createInvoice({ orderId: createdOrder._id, scamberBody: data });

        if (response.status === 200) {
          const data = await response.data;

          setGenerateInvoice(generated => generated = true);

          switch (data.title) {
            case "REJEITADO":
              console.log('__INVOICE REJECTED: ', data);
              setInvoiceGenerated(generated => generated = false);
              setResendGenerateInvoice(generated => generated = true);
              Swal.close();
              break;
            case "DENEGADO":
              console.log('__INVOICE DENEGADO: ', data);
              setInvoiceGenerated(generated => generated = false);
              setResendGenerateInvoice(generated => generated = true);
              Swal.close();
              break;
            case "CANCELADO":
              console.log('__INVOICE CANCELADO: ', data);
              setInvoiceGenerated(generated => generated = false);
              setResendGenerateInvoice(generated => generated = true);
              Swal.close();
              break;
            case "CONCLUIDO":
              console.log('__INVOICE SUCCESS: ', data);
              setInvoiceGenerated(generated => generated = true);
              setResendGenerateInvoice(generated => generated = false);
              setPlugnotasReturn([{ message: data.message, type: data.title, invoiceId: data.payload.id }]);
              Swal.close();
              break;
            default:
              setPlugnotasReturn([{ message: data.message }]);
              setConsultPlugNotasButton(status => status = true);
              Swal.close();
          }

        }

      } catch (error) {
        Swal.fire({
          type: "info",
          html: nota.formatErrorMessage(error.response, 'FNLOD'),
          title: "",
        });
      }

    }
  }

  const resendEmailInvoice = () => {

    const nota = new PlugNotas();

    if (createdOrder) {

      nota.generateSimpleInvoice(createdOrder._id).then((response) => {
        Swal.fire('', 'Email com a nota do pedido reenviado com sucesso!', 'success');
      }).catch((error) => {
        Swal.fire({
          type: "info",
          html: nota.formatErrorMessage(error.response, 'FNLOD'),
          title: "",
        });
      })
    } else {
      Swal.fire('Pedido não encontrado para reenvio do email da nota de pedido!');
    }
  }

  const onClosePdvModal = () => {
    setConfirmPayment(false);
    setCheckFinishStep(false);
    handleReset();
  }

  const handleGenerateInvoice = () => {

    setFormTryAgainNf(nfResponse => nfResponse = true);
  }

  console.log("__DADOS: ", plugnotasReturn);
  return (
    <>

      {/* Drawer Categories */}
      <Drawer
        open={drawerCategories}
        className={classes.drawer}
        anchor="right"
        classes={{
          paper: classes.drawerPaper,
        }}
      >

        <DrawerHeader icon={<Icon icon={["far", "list-alt"]} />} title="Categorias" onClick={handleDrawerCategories} />

        <DrawerContent id="DrawerCategories">

          {listSelectedCategorization.length > 0 && (
            <HistoryCategory>
              {listSelectedCategorization.map((selectedCategorization, index) => {
                if (index === 0) {
                  return (<HistoryItem key={index} text={selectedCategorization.categorization.nome} onClick={() => handleCategorizationBack(selectedCategorization.oldCategorizationList, index)} />);
                } else {
                  return (<HistoryItem text={selectedCategorization.categorization.nome} onClick={() => handleCategorizationBack(selectedCategorization.oldCategorizationList, index)} />);
                }
              })}

            </HistoryCategory>
          )}

          <MenuCategory>
            {listActiveCategorization?.map((category) => (
              <MenuCategoryItem key={category._id} text={category.nome} inputType="radio" inputName="category_select" onClick={() => handleCategorization(category)} />
            ))}

          </MenuCategory>

          <WrapApplyFilter>
            <Button
              type="submit"
              styleType="default"
              buttonSize="small"
              textColor="#FFFFFF"
              buttonText="Aplicar"
              buttonColor="#14233E"
              borderColor="#14233E"
              onClick={handleDrawerCategories}
            />
          </WrapApplyFilter>
        </DrawerContent>

      </Drawer>

      <Drawer
        open={drawerSize}
        className={classes.drawer}
        anchor="right"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <DrawerHeader icon={<Icon icon={["far", "list-alt"]} />} title="Tamanhos" onClick={handleDrawerSizes} />
        <DrawerContent id="DrawerFilterSizes">

          {activeSize && (
            <HistoryFilters>
              <RemoveFilter text={activeSize.nome} onClick={() => setActiveSize(undefined)} />
            </HistoryFilters>
          )}

          <div class="_filter-item _filter-show">
            {listActiveSizes?.map((sizeGroup, index) => (
              <ListSize title={sizeGroup.nome}>
                <ListSizePanel>
                  <div className="wrap-filter-results">
                    <Grid container spacing={1}>
                      {sizeGroup.tamanhos.map((size, index) => (
                        <Grid item lg={3} md={3} sm={3} xs={3} key={index}>
                          <label>
                            <input type="radio" className="btn-select--filter bordered" onClick={() => setActiveSize(size)} name="size" />
                            <span className="title">
                              {size.nome}
                            </span>
                            <span className="overlay"></span>
                          </label>
                        </Grid>
                      ))}

                    </Grid>
                  </div>

                  <WrapApplyFilter>
                    <Button
                      type="submit"
                      styleType="default"
                      buttonSize="small"
                      textColor="#FFFFFF"
                      buttonText="Aplicar"
                      buttonColor="#14233E"
                      borderColor="#14233E"
                      onClick={handleDrawerSizes}
                    />
                  </WrapApplyFilter>
                </ListSizePanel>
              </ListSize>
            ))}
          </div>
        </DrawerContent>
      </Drawer>

      {/* Drawer Filtros */}
      <Drawer
        open={drawerFilter}
        className={classes.drawer}
        anchor="right"
        classes={{
          paper: classes.drawerPaper,
        }}
      >

        <DrawerHeader icon={<Icon icon={["far", "filter"]} />} title="Filtros" onClick={handleDrawerFilter} />

        <DrawerContent>
          <FormFilters onSubmit={handleSubmit(onSearch)}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  id="outlined-add-title"
                  label="Busca por palavra chave"
                  type="text"
                  variant="outlined"
                  size={matches ? "small" : "small"}
                  name="filterby"
                  fullWidth
                  inputRef={register({
                    required: false,
                  })}
                  inputProps={{
                    maxLength: 40,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                    Ordenar por
                  </InputLabel>
                  <Select
                    native
                    label="Ordernar por"
                    inputRef={register({
                      required: false
                    })}
                    inputProps={{
                      name: "order",
                    }}
                  >
                    <option value=""></option>
                    <option value={-1}>Maior Preço</option>
                    <option value={1}>Menor Preço</option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  id="outlined-category"
                  label="Categoria"
                  type="text"
                  variant="outlined"
                  size={matches ? "small" : "small"}
                  name="add_category"
                  value={allCategorizationLabel}
                  fullWidth
                  error={!!errors.add_category}
                  inputRef={register({
                    required: false,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onClick={() => setDrawerCategories(!drawerCategories)}
                  inputProps={{
                    endadornment: (
                      <InputAdornment position="end">
                        <Icon icon={["far", "chevron-right"]} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <Autocomplete
                  filterOptions={filterOptions}
                  id="outlined-brand"
                  options={brands}
                  includeInputInList
                  freeSolo
                  getOptionLabel={(option) => option.nome}
                  getOptionSelected={(option) => option._id}
                  fullWidth
                  value={brand}
                  onChange={(e, value) => handleBrand(e, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Marca"
                      name="brand"
                      variant="outlined"
                      size={matches ? "small" : "medium"}
                      error={!!errors.brand}
                      inputRef={register({
                        required: false,
                      })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <Autocomplete
                  id="outlined-condition"
                  autoComplete={true}
                  options={conditions}
                  getOptionLabel={(option) => option.nome}
                  getOptionSelected={(option) => option._id}
                  size="small"
                  fullWidth
                  value={condition}
                  onChange={(e, value) => handleCondition(e, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Condição"
                      name="add_condition"
                      variant="outlined"
                      size={matches ? "small" : "small"}
                      error={!!errors.add_condition}
                      inputRef={register({
                        required: false,
                      })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>


              <Grid item md={12} xs={12}>
                <TextField
                  id="outlined-category"
                  label="Tamanhos"
                  type="text"
                  variant="outlined"
                  size={matches ? "small" : "small"}
                  name="add_category"
                  value={activeSize?.nome}
                  fullWidth
                  error={!!errors.add_category}
                  inputRef={register({
                    required: false,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onClick={() => setDrawerSize(!drawerSize)}
                  inputProps={{
                    endadornment: (
                      <InputAdornment position="end">
                        <Icon icon={["far", "chevron-right"]} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                    Tipo de Frete
                  </InputLabel>
                  <Select
                    native
                    label="Tipo de Frete"
                    inputRef={register({
                      required: false
                    })}
                    inputProps={{
                      name: "frete_type",
                    }}
                  >
                    <option value=""></option>
                    <option value={"operador_logistico"}>Correios</option>
                    <option value={"entrega_maos"}>Entrega em mãos</option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  id="outlined-login"
                  label="Pontos: Mínimo"
                  type="number"
                  variant="outlined"
                  size="small"
                  name="pontosMinimos"
                  defaultValue={0}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  id="outlined-login"
                  label="Pontos: Máximo"
                  type="number"
                  variant="outlined"
                  size="small"
                  name="pontosMaximos"
                  defaultValue={9999}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <Button
                  type="submit"
                  styleType="default"
                  buttonSize="small"
                  textColor="#FFFFFF"
                  buttonText="Aplicar"
                  buttonColor="#14233E"
                  borderColor="#14233E"
                // onClick={() => console.log("Load more...")}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <Button
                  styleType="default"
                  buttonSize="small"
                  textColor="#C2403F"
                  buttonText="Limpar"
                  buttonColor="#F1F1F1"
                  borderColor="#F1F1F1"
                  onClick={() => onCleanFilter()}
                />
              </Grid>
            </Grid>
          </FormFilters>
        </DrawerContent>

      </Drawer>

      {/* Drawer Sacola */}
      <Drawer
        open={drawerCart}
        className={classes.drawer}
        anchor="right"
        classes={{
          paper: classes.drawerPaper,
        }}
      >

        <DrawerHeader icon={<Icon icon={["far", "shopping-bag"]} />} title="Sacola" cartCount={cart && cart.itens.length > 0
          ? (<CountCartItens className="cart-count">{cart && cart.itens.length}</CountCartItens>)
          : null} onClick={handleDrawerCart} />

        <DrawerContent>
          {cart && cart.itens.map((item) => (
            <ProductInline
              productTitle={item.titulo}
              image={item.fotos[0] ? cartService.loadImage(item.fotos[0]) : ""}
              productPricePoints={item.pontos_por && item.pontos_por.$numberDecimal}
              productPrice={item.pontos_por && calcPrice({ points: item.pontos_por.$numberDecimal })}
              handleRemoveItem={() => removeAnuncios({ anuncio: item._id })}
            />
          ))}

          {cart && (
            <ListMarkup>
              <ListBlockItem title="Total em pontos">
                <PricePoints size="medium" value={cart.valor_pontos} />
              </ListBlockItem>
              <ListBlockItem title="Taxa Scamb">
                <PriceReal size="medium" value={parseFloat(cart.valor_taxa).toFixed(2)} />
              </ListBlockItem>
              <ListBlockItem title="Valor dos Produtos">
                <PriceReal size="medium" value={calcPriceAllProducts({ allProdcuts: cart.itens })} />
              </ListBlockItem>
              <ListBlockItem title="Total em reais">
                <PriceReal size="medium" value={parseFloat(Number(cart.valor_taxa) + Number(calcPriceAllProducts({ allProdcuts: cart.itens }))).toFixed(2)} />
              </ListBlockItem>
            </ListMarkup>
          )}

          {!cart && (
            <WrappLoadMore>
              <ListBlockItem title="Não tem produtos adicionados a sacola!" />
            </WrappLoadMore>
          )}

          {cart && (
            <WrappLoadMore>
              <Button
                styleType="default"
                buttonSize="small"
                buttonColor="#C2403F"
                textColor="#FFFFFF"
                buttonText="Finalizar compra"
                borderColor="#C2403F"
                maxWidth={200}
                onClick={handleDialogConfirmAccount}
              />
            </WrappLoadMore>
          )}
        </DrawerContent>

      </Drawer>

      {/* Content */}
      <WrapperPage>
        <WrapperHead>
          <PageHead>
            <PageTitle> Todos os produtos </PageTitle>
            <PageSubtitle>Exibindo {searchAds && searchAds.length} produto </PageSubtitle>
          </PageHead>

          <PageHeadActions>

            <Button
              styleType="default"
              buttonSize="small"
              textColor="#FFF"
              buttonColor="#F25F68"
              borderColor="#F9F9F9"
              maxWidth={200}
              onClick={handleQrCode}
            ><Icon icon={["far", "qrcode"]} />
              Ler QR Code
            </Button>

            <Button
              styleType="default"
              buttonSize="small"
              textColor="#3A3E4A"
              buttonColor="#F1F1F1"
              borderColor="#F9F9F9"
              maxWidth={200}
              onClick={handleDrawerFilter}
            ><Icon icon={["far", "filter"]} /> Filtrar</Button>

            <Button
              styleType="default"
              buttonSize="small"
              textColor="#3A3E4A"
              buttonColor="#F1F1F1"
              borderColor="#F9F9F9"
              maxWidth={200}
              onClick={handleDrawerCart}
            ><Icon icon={["far", "shopping-bag"]} />
              Sacola
              {cart && cart.itens.length > 0
                ? (<CountCartItens>{cart && cart.itens.length}</CountCartItens>)
                : null}
            </Button>
          </PageHeadActions>
        </WrapperHead>

        <Grid container spacing={2}>
          {searchAds && searchAds?.map((ad, index) => (
            <Grid item md={3} sm={4} xs={6} key={index}>
              <ProductBox
                productTitle={ad.titulo}
                image={ad.fotos[0] ? cartService.loadImage(ad.fotos[0]) : ""}
                adWeight={ad.tamanho_exibicao?.nome}
                productPricePoints={ad.pontos_por ? ad.pontos_por.$numberDecimal : ""}
                productPrice={ad.pontos_por && calcPrice({ points: ad.pontos_por.$numberDecimal })}
                alredyInCart={handleItensAlredyInCart({ item: ad._id })}
                handleAddItenCart={() => { !handleItensAlredyInCart({ item: ad._id }) ? addAnuncios({ anuncio: ad._id }) : removeAnuncios({ anuncio: ad._id }) }}
              />
            </Grid>
          ))}

        </Grid>

        {limit <= searchAds?.length ?
          <WrappLoadMore>
            <Button
              styleType="default"
              buttonSize="small"
              buttonColor="#C2403F"
              textColor="#FFFFFF"
              buttonText="Mostrar mais produtos"
              borderColor="#C2403F"
              maxWidth={200}
              onClick={() => loadMoreAds()}
            />
          </WrappLoadMore>
          : null}

      </WrapperPage>

      <ModalDialog
        dialogTitle="Leitura de QR Code"
        wrapperClassName="DialogStyled"
        ref={refReadQrCode}
        isFullscreen={false}
        showCloseIcon={false}
        customMethod={() => { setIsOnQRCodeReader(false); setLastQrCodeProduct(""); }}
      >
        {isOnQRCodeReader &&
          (<QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%' }}
          />)
        }

        <div style={{ textAlign: 'center', width: '100%', padding: '10px' }}>
          <button className="btn btn-primary" onClick={handleCloseQrCode}>Fechar Leitor</button>
        </div>

      </ModalDialog>

      <ModalDialog
        dialogTitle="Pedido"
        wrapperClassName="DialogStyled"
        ref={refConfirmAccount}
        isFullscreen={true}
        showCloseIcon={false}
        customMethod={onClosePdvModal}
      >
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {/* <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>

              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment> */}

          <React.Fragment>

            <div style={{ display: `${activeStep === 0 ? 'block' : 'none'}` }}>

              <WrapperFormConfirmAccount>
                <FormTitle>Informe o email do comprador</FormTitle>
                <FormConfirmAccount onSubmit={handleSubmitUserEmail(handleFormUserEmail)}>
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={3} />
                    <Grid item md={4} xs={12}>
                      <FormTextField textAlign="center" placeholder="meuemail@gmail.com" error={!!errorsUserEmail.user_email} name="user_email" ref={registerUserEmailField({
                        required: true, pattern: {
                          value: /\S+@\S+\.\S+/,
                        }
                      })} />
                      {errorsUserEmail.user_email && <FieldError> Digite um email válido </FieldError>}
                    </Grid>
                    <Grid item md={4} xs={3} />
                    <Grid item md={12} xs={12}>
                      <WrapContinueButton>
                        <Button
                          type="button"
                          styleType="default"
                          buttonSize="small"
                          textColor="#777777"
                          buttonColor="#F1F1F1"
                          borderColor="#F1F1F1"
                          maxWidth={200}
                          onClick={() => refConfirmAccount.current.closeModal()}
                        >
                          <Icon icon={["far", "chevron-left"]} /> Voltar
                        </Button>
                        <Button
                          type="submit"
                          styleType="default"
                          buttonSize="small"
                          textColor="#FFFFFF"
                          buttonColor="#C2403F"
                          borderColor="#C2403F"
                          maxWidth={200}
                        >
                          {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'} <Icon icon={["far", "chevron-right"]} />
                        </Button>
                      </WrapContinueButton>
                    </Grid>
                  </Grid>
                </FormConfirmAccount>
              </WrapperFormConfirmAccount>

            </div>

            <div style={{ display: `${activeStep === 1 ? 'block' : 'none'}` }}>

              <FormCheckout onSubmit={handleSubmitCheckout(handleFormCheckout)}>

                <CheckoutCard icon={<Icon icon={["far", "user"]} />} title="Dados pessoais">
                  <WrapperFormPersonalData>
                    <Grid container spacing={1}>
                      <Grid item md={12} xs={12}>
                        <FormTextField
                          error={!!errorsCheckout.user_email}
                          placeholder="E-Mail *"
                          name="user_email"
                          ref={registerCheckoutField({ required: true })}
                        />
                        {errorsCheckout.user_email && <FieldError> Preencha o email </FieldError>}
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <FormTextField
                          error={!!errorsCheckout.user_full_name}
                          placeholder="Nome completo *"
                          name="user_full_name"
                          ref={registerCheckoutField({ required: true })}
                        />
                        {errorsCheckout.user_full_name && <FieldError> Preencha o nome completo </FieldError>}
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <FormTextField
                          error={!!errorsCheckout.user_cpf}
                          placeholder="CPF *"
                          name="user_cpf"
                          ref={registerCheckoutField({ required: true })}
                          className='user_cpf'
                        />
                        {errorsCheckout.user_cpf && <FieldError> Preencha o cpf </FieldError>}
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <FormTextField
                          error={!!errorsCheckout.user_phone}
                          placeholder="Telefone *"
                          name="user_phone"
                          ref={registerCheckoutField({ required: true })}
                          className='user_phone'
                        />
                        {errorsCheckout.user_phone && <FieldError> Preencha o telefone </FieldError>}
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <FormTextField
                          error={!!errorsCheckout.user_cep}
                          placeholder="CEP *"
                          name="user_cep"
                          ref={registerCheckoutField({ required: true })}
                          className='user_cep'
                          onChange={(e) => setCep(e.target.value)}
                          onBlur={findAddressByCEP}
                        />
                        {errorsCheckout.user_cep && <FieldError> Preencha o CEP </FieldError>}
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <FormTextField
                          error={!!errorsCheckout.user_state}
                          placeholder="Estado *"
                          name="user_state"
                          ref={registerCheckoutField({ required: true })}
                          className='user_state'
                        />
                        {errorsCheckout.user_state && <FieldError> Preencha o estado </FieldError>}
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <FormTextField
                          error={!!errorsCheckout.user_city}
                          placeholder="Cidade *"
                          name="user_city"
                          ref={registerCheckoutField({ required: true })}
                          className='user_city'
                        />
                        {errorsCheckout.user_city && <FieldError> Preencha a cidade </FieldError>}
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <FormTextField
                          error={!!errorsCheckout.user_adress}
                          placeholder="Endereço *"
                          name="user_adress"
                          ref={registerCheckoutField({ required: true })}
                          className='user_adress'
                        />
                        {errorsCheckout.user_adress && <FieldError> Preencha o endereço </FieldError>}
                      </Grid>
                      <Grid item md={4} xs={4}>
                        <FormTextField
                          error={!!errorsCheckout.user_number}
                          placeholder="Número *"
                          name="user_number"
                          ref={registerCheckoutField({ required: true })}
                          className='user_number'
                        />
                        {errorsCheckout.user_number && <FieldError> Preencha o Número </FieldError>}
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <FormTextField
                          error={!!errorsCheckout.user_district}
                          placeholder="Bairro *"
                          name="user_district"
                          ref={registerCheckoutField({ required: true })}
                          className='user_district'
                        />
                        {errorsCheckout.user_district && <FieldError> Preencha o bairro </FieldError>}
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <FormTextField
                          placeholder="Complemento"
                          name="user_complement"
                          ref={registerCheckoutField({ required: false })}
                          className='user_complement'
                        />
                      </Grid>
                    </Grid>
                  </WrapperFormPersonalData>

                </CheckoutCard>

                <CheckoutCard icon={<Icon icon={["far", "university"]} />} title="Selecionar forma de pagamento">
                  <CheckoutCardWrapperContent>

                    <PaymentOption title="Máquininha" icon={<Icon icon={["far", "credit-card"]} />}>
                      <InputRadio type="radio" name="payment_option" defaultChecked={true} onClick={() => setPaymentMethod('payment_machine')} />
                    </PaymentOption>

                    <PaymentOption title="Link de Pagamento" icon={<Icon icon={["far", "credit-card"]} />}>
                      <InputRadio type="radio" name="payment_option" onClick={() => setPaymentMethod('card')} />
                    </PaymentOption>

                  </CheckoutCardWrapperContent>
                </CheckoutCard>

                <CheckoutCard icon={<Icon icon={["far", "boxes"]} />} title="Produtos">
                  <WrapProducts>
                    {cart && cart.itens.map((item) => (
                      <ProductInline
                        productTitle={item.titulo}
                        image={item.fotos[0] ? cartService.loadImage(item.fotos[0]) : ""}
                        productPricePoints={item.pontos_por && item.pontos_por.$numberDecimal}
                        productPrice={item.pontos_por && calcPrice({ points: item.pontos_por.$numberDecimal })}
                        handleRemoveItem={() => removeAnuncios({ anuncio: item._id })}
                      />
                    ))}
                  </WrapProducts>

                  <WrapCheckoutButton>
                    <Button
                      type="button"
                      styleType="default"
                      buttonSize="small"
                      textColor="#FFFFFF"
                      buttonText="Adicionar mais produtos"
                      buttonColor="#14233E"
                      borderColor="#14233E"
                      onClick={handleAddMoreProducts}
                      maxWidth={240}
                    />
                  </WrapCheckoutButton>

                </CheckoutCard>

                <CheckoutCard icon={<Icon icon={["far", "user"]} />} title="Usuário">

                  {cart && (
                    <ListMarkup className="ListMarkup Checkout">
                      {currentBalance !== 0 && currentBalance && (
                        <>
                          <ListBlockItem title="Pontos do usuário">
                            <PricePoints size="medium" value={currentBalance} />
                          </ListBlockItem>
                          {/* <ListBlockItem title="Equivalente em Reais">
                          <PriceReal size="medium" value={calcPrice({points: currentBalance})} />
                        </ListBlockItem> */}
                        </>
                      )}
                    </ListMarkup>
                  )}
                </CheckoutCard>

                <CheckoutCard icon={<Icon icon={["far", "list-alt"]} />} title="Resumo do pedido">

                  {cart && (
                    <ListMarkup className="ListMarkup Checkout">
                      <ListBlockItem title="Total em Pontos" bgColor="#F6F6F9">
                        <PricePoints size="medium" value={cart.valor_pontos_original ? cart.valor_pontos_original : cart.valor_pontos} />
                      </ListBlockItem>

                      <ListBlockItem title="Valor em Reais" bgColor="#FFFFFF">
                        <PriceReal size="medium" value={cart.valor_pontos_original && calcPrice({ points: cart?.valor_pontos_original })} />
                      </ListBlockItem>

                      {/* <ListBlockItem title="Total em reais" bgColor="#F6F6F9">
                        <PriceReal size="medium" value={ cart.valor_pontos_original && couponApplied ? calcPrice({points: cart?.valor_pontos_original}) : parseFloat(cart.valor).toFixed(2)} />
                      </ListBlockItem> */}

                      <ListBlockItem title="Taxa Scamb"  >
                        <PriceReal size="medium" value={parseFloat(cart.valor_taxa).toFixed(2)} />
                      </ListBlockItem>

                      {cart.valor_faltante && cart.valor_pontos_faltante ? (
                        <SkippedStitches valuePoints={parseFloat(cart.valor_pontos_faltante).toFixed(0)}>
                          <OrderDetail
                            title="Valor da Compra"
                            valuePrice={parseFloat(cart.valor_faltante).toFixed(2)}
                            bgColor="#F1F1F1" />
                        </SkippedStitches>
                      ) : null}
                    </ListMarkup>
                  )}
                </CheckoutCard>

                <CheckoutCard icon={<Icon icon={["far", "tag"]} />} title="Cupom">

                  <CupomBlock>
                    <FieldWrapper>
                      <CupomField
                        id="outlined-complement"
                        label="Digite o cupom"
                        variant="outlined"
                        name="cupom"
                        value={coupon.coupon_code}
                        onChange={(e) => setCoupon({ coupon_code: e.target.value })}
                        size={"small"}
                        fullWidth
                        error={couponApplied ? true : false}
                        disabled={couponApplied ? true : false}
                      />

                      {!couponApplied ? (
                        <Button className="btn-add-payment" onClick={() => handleInsertCoupom()} >
                          Aplicar
                        </Button>
                      ) : (
                        <Button className="btn-add-payment" onClick={() => handleDeleteCoupon()} >
                          Remover
                        </Button>
                      )}

                    </FieldWrapper>
                  </CupomBlock>

                  {couponApplied && coupon && cart?.valor_pontos_original && (
                    <ListMarkup className="ListMarkup Checkout">
                      <ListBlockItem title="Desconto em Pontos">
                        <PricePoints size="medium" className="discount" value={`${Number(cart.valor_pontos) - Number(cart.valor_pontos_original)}`} />
                      </ListBlockItem>

                      <ListBlockItem title="Desconto em Reais">
                        <WrapDiscountValue>
                          <span className="minus">-</span> <PriceReal size="medium" className="discount" value={calcPrice({ points: cart?.valor_pontos_original - cart?.valor_pontos })} />
                        </WrapDiscountValue>
                      </ListBlockItem>

                      <ListBlockItem title="Novo total em Pontos">
                        <PricePoints size="medium" value={`${cart.valor_pontos_faltante}`} />
                      </ListBlockItem>

                      <ListBlockItem title="Novo total em Reais">
                        <PriceReal size="medium" value={parseFloat(cart.valor).toFixed(2)} />
                      </ListBlockItem>

                    </ListMarkup>
                  )}
                  <WrapCheckoutButton>

                    <Button
                      type="button"
                      styleType="default"
                      buttonSize="small"
                      textColor="#777777"
                      buttonColor="#F1F1F1"
                      borderColor="#F1F1F1"
                      maxWidth={200}
                      onClick={handleBack}
                    >
                      <Icon icon={["far", "chevron-left"]} /> Voltar
                    </Button>

                    <Button
                      type="submit"
                      styleType="default"
                      buttonSize="small"
                      textColor="#FFFFFF"
                      buttonColor="#C2403F"
                      borderColor="#C2403F"
                      maxWidth={200}
                    // onClick={handleNext}
                    >
                      {activeStep === steps.length - 1 ? 'Finalizar' : 'Finalizar Pedido'} <Icon icon={["far", "chevron-right"]} />
                    </Button>
                  </WrapCheckoutButton>
                </CheckoutCard>
              </FormCheckout>

            </div>

            <div style={{ display: `${activeStep === 2 ? 'block' : 'none'}` }}>

              {!generateInvoice && (
                <CheckoutThanks
                  title={confirmPayment ? ("Pagamento confirmado com sucesso!") : ("Aguardando confirmação de pagamento")}
                  icon={confirmPayment ? <Icon id="checked" icon={["far", "check"]} /> : <Icon id="checked" icon={["fas", "exclamation"]} />}
                  iconType={confirmPayment ? "success" : "alert"}
                >
                  {confirmPayment
                    ? (
                      <HText marginTop={15}>
                        Clique no botão abaixo para ver a nota fiscal
                      </HText>
                    )
                    : (
                      <HText marginTop={15}>
                        Clique no botão abaixo para confirmar o pagamento <br />
                        Ou em cancelar, para cancelar o pedido.
                      </HText>
                    )}

                  {paymentMethod === "card" && (
                    <WrapExternalLink>
                      <Icon icon={["far", "external-link"]} /> <a href={paymentLink} target="_blank">Acessar link de pagamento</a>
                    </WrapExternalLink>
                  )}

                  <WrapCheckoutButton className="actions">

                    {confirmPayment ? (
                      <>
                        <Button
                          type="button"
                          styleType="default"
                          buttonSize="small"
                          textColor="#FFFFFF"
                          buttonColor="#C2403F"
                          borderColor="#C2403F"
                          maxWidth={280}
                          onClick={handleCreateInvoice}
                        >
                          Gerar Nota Fiscal
                        </Button>

                        <Button
                          type="button"
                          styleType="default"
                          buttonSize="small"
                          textColor="#FFFFFF"
                          buttonColor="#14233e"
                          borderColor="#14233e"
                          maxWidth={280}
                          onClick={handlePlugNotas}
                        >
                          Gerar Recibo
                        </Button>
                        <Button
                          type="button"
                          styleType="default"
                          buttonSize="small"
                          textColor="#FFFFFF"
                          buttonColor="#14233e"
                          borderColor="#14233e"
                          maxWidth={280}
                          onClick={resendEmailInvoice}
                        >
                          Reenviar Recibo por email
                        </Button>
                      </>
                    ) : (
                      <Button
                        type="button"
                        styleType="default"
                        buttonSize="small"
                        textColor="#FFFFFF"
                        buttonColor="#C2403F"
                        borderColor="#C2403F"
                        maxWidth={280}
                        onClick={finishOrder}
                      >
                        Confirmar pagamento
                      </Button>
                    )}

                    {!confirmPayment &&
                      <Button
                        type="button"
                        styleType="default"
                        buttonSize="small"
                        textColor="#777777"
                        buttonColor="#F1F1F1"
                        borderColor="#F1F1F1"
                        maxWidth={280}
                        onClick={cancelOrder}
                        disabled={confirmPayment}
                      >
                        Cancelar
                      </Button>
                    }


                  </WrapCheckoutButton>
                </CheckoutThanks>
              )}

              {generateInvoice && (
                <CheckoutThanks
                  title={invoiceGenerated ? ("Nota fiscal gerada!") : ("Ocorreu um erro ao tentar gerar a nota fiscal")}
                  icon={invoiceGenerated ? <Icon id="checked" icon={["far", "file-invoice"]} /> : <Icon id="checked" icon={["fas", "exclamation"]} />}
                  iconType={invoiceGenerated ? "success" : "alert"}
                >
                  <HText marginTop={15}>
                    {invoiceGenerated
                      ? ("Clique no botão abaixo para ver a nota fiscal")
                      : ("Tente gerar a nota novamente.")
                    }
                  </HText>

                  <MessageToast>
                    <ToastTitle>PlugNotas:</ToastTitle> {plugnotasReturn[0].message && plugnotasReturn[0].message}
                  </MessageToast>

                  {invoiceGenerated && (
                    <WrapCheckoutButton className="actions">
                      <Button
                        type="button"
                        styleType="default"
                        buttonSize="small"
                        textColor="#FFFFFF"
                        buttonColor="#C2403F"
                        borderColor="#C2403F"
                        maxWidth={280}
                        buttonLink={plugnotasReturn[0].invoiceId && consultInvoicePdf({ invoiceId: plugnotasReturn[0].invoiceId })}
                        target="_blank"
                        buttonText="Ver Nota Fiscal"
                      />
                    </WrapCheckoutButton>
                  )}

                  {/* {resendGenerateInvoice && ( */}
                  <FormTryAgainNF onSubmit={handleSubmitFormTryAgainNf(handleFormTryAgainNf)} style={{ display: `${resendGenerateInvoice ? "block" : "none"}` }}>
                    <CheckoutCard icon={<Icon icon={["far", "user"]} />} title="Revise os dados pessoais">
                      <WrapperFormPersonalData>
                        <Grid container spacing={1}>
                          <Grid item md={12} xs={12}>
                            <FormTextField
                              error={!!errorsFormTryAgainNf.user_email}
                              placeholder="E-Mail *"
                              name="user_email"
                              ref={registerFormTryAgainNf({ required: true })}
                            />
                            {errorsFormTryAgainNf.user_email && <FieldError> Preencha o email </FieldError>}
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <FormTextField
                              error={!!errorsFormTryAgainNf.user_full_name}
                              placeholder="Nome completo *"
                              name="user_full_name"
                              ref={registerFormTryAgainNf({ required: true })}
                            />
                            {errorsFormTryAgainNf.user_full_name && <FieldError> Preencha o nome completo </FieldError>}
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <FormTextField
                              error={!!errorsFormTryAgainNf.user_cpf}
                              placeholder="CPF *"
                              name="user_cpf"
                              ref={registerFormTryAgainNf({ required: true })}
                              className='user_cpf'
                            />
                            {errorsFormTryAgainNf.user_cpf && <FieldError> Preencha o cpf </FieldError>}
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <FormTextField
                              error={!!errorsFormTryAgainNf.user_phone}
                              placeholder="Telefone *"
                              name="user_phone"
                              ref={registerFormTryAgainNf({ required: true })}
                              className='user_phone'
                            />
                            {errorsFormTryAgainNf.user_phone && <FieldError> Preencha o telefone </FieldError>}
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <FormTextField
                              error={!!errorsFormTryAgainNf.user_cep}
                              placeholder="CEP *"
                              name="user_cep"
                              ref={registerFormTryAgainNf({ required: true })}
                              className='user_cep'
                              onChange={(e) => setCep(e.target.value)}
                              onBlur={findAddressByCEP}
                            />
                            {errorsFormTryAgainNf.user_cep && <FieldError> Preencha o CEP </FieldError>}
                          </Grid>
                          <Grid item md={6} xs={6}>
                            <FormTextField
                              error={!!errorsFormTryAgainNf.user_state}
                              placeholder="Estado *"
                              name="user_state"
                              ref={registerFormTryAgainNf({ required: true })}
                              className='user_state'
                            />
                            {errorsFormTryAgainNf.user_state && <FieldError> Preencha o estado </FieldError>}
                          </Grid>
                          <Grid item md={6} xs={6}>
                            <FormTextField
                              error={!!errorsFormTryAgainNf.user_city}
                              placeholder="Cidade *"
                              name="user_city"
                              ref={registerFormTryAgainNf({ required: true })}
                              className='user_city'
                            />
                            {errorsFormTryAgainNf.user_city && <FieldError> Preencha a cidade </FieldError>}
                          </Grid>
                          <Grid item md={8} xs={8}>
                            <FormTextField
                              error={!!errorsFormTryAgainNf.user_adress}
                              placeholder="Endereço *"
                              name="user_adress"
                              ref={registerFormTryAgainNf({ required: true })}
                              className='user_adress'
                            />
                            {errorsFormTryAgainNf.user_adress && <FieldError> Preencha o endereço </FieldError>}
                          </Grid>
                          <Grid item md={4} xs={4}>
                            <FormTextField
                              error={!!errorsFormTryAgainNf.user_number}
                              placeholder="Número *"
                              name="user_number"
                              ref={registerFormTryAgainNf({ required: true })}
                              className='user_number'
                            />
                            {errorsFormTryAgainNf.user_number && <FieldError> Preencha o Número </FieldError>}
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <FormTextField
                              error={!!errorsFormTryAgainNf.user_district}
                              placeholder="Bairro *"
                              name="user_district"
                              ref={registerFormTryAgainNf({ required: true })}
                              className='user_district'
                            />
                            {errorsFormTryAgainNf.user_district && <FieldError> Preencha o bairro </FieldError>}
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <FormTextField
                              placeholder="Complemento"
                              name="user_complement"
                              ref={registerFormTryAgainNf({ required: false })}
                              className='user_complement'
                            />
                          </Grid>
                        </Grid>
                        <WrapCheckoutButton className="actions">
                          <Button
                            type="submit"
                            styleType="default"
                            buttonSize="small"
                            textColor="#FFFFFF"
                            buttonColor="#C2403F"
                            borderColor="#C2403F"
                            maxWidth={280}
                          // onClick={handlePlugNotas}
                          >
                            Enviar e Gerar Nota
                          </Button>
                        </WrapCheckoutButton>
                      </WrapperFormPersonalData>
                    </CheckoutCard>

                  </FormTryAgainNF>


                  <WrapCheckoutButton className="actions">

                    {consultPlugNotasButton && (
                      <Button
                        type="button"
                        styleType="default"
                        buttonSize="small"
                        textColor="#FFFFFF"
                        buttonColor="#C2403F"
                        borderColor="#C2403F"
                        maxWidth={280}
                        onClick={checkInvoice}
                      >
                        Consultar Nota Fiscal
                      </Button>
                    )}

                    {confirmPayment ? (
                      <>
                        <Button
                          type="button"
                          styleType="default"
                          buttonSize="small"
                          textColor="#FFFFFF"
                          buttonColor="#14233e"
                          borderColor="#14233e"
                          maxWidth={280}
                          onClick={handlePlugNotas}
                        >
                          Ver Recibo
                        </Button>
                        <Button
                          type="button"
                          styleType="default"
                          buttonSize="small"
                          textColor="#FFFFFF"
                          buttonColor="#14233e"
                          borderColor="#14233e"
                          maxWidth={280}
                          onClick={resendEmailInvoice}
                        >
                          Reenviar Recibo por email
                        </Button>
                      </>
                    ) : (
                      <Button
                        type="button"
                        styleType="default"
                        buttonSize="small"
                        textColor="#FFFFFF"
                        buttonColor="#C2403F"
                        borderColor="#C2403F"
                        maxWidth={280}
                        onClick={finishOrder}
                      >
                        Confirmar pagamento
                      </Button>
                    )}

                    {!confirmPayment &&
                      <Button
                        type="button"
                        styleType="default"
                        buttonSize="small"
                        textColor="#777777"
                        buttonColor="#F1F1F1"
                        borderColor="#F1F1F1"
                        maxWidth={280}
                        onClick={cancelOrder}
                        disabled={confirmPayment}
                      >
                        Cancelar
                      </Button>
                    }


                  </WrapCheckoutButton>
                </CheckoutThanks>
              )}

              <WrapCheckoutButton>

                <Button
                  type="button"
                  styleType="default"
                  buttonSize="small"
                  textColor="#777777"
                  buttonColor="#F1F1F1"
                  borderColor="#F1F1F1"
                  maxWidth={200}
                  disabled={checkLastStep}
                  onClick={handleBack}
                >
                  <Icon icon={["far", "chevron-left"]} /> Voltar
                </Button>

                <Button
                  type="button"
                  styleType="default"
                  buttonSize="small"
                  textColor="#FFFFFF"
                  buttonColor="#C2403F"
                  borderColor="#C2403F"
                  maxWidth={200}
                  onClick={handleFinishWizard}
                  disabled={!confirmPayment}
                >
                  {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'} <Icon icon={["far", "chevron-right"]} />
                </Button>
              </WrapCheckoutButton>
            </div>

            {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box> */}
          </React.Fragment>
        </Box>

      </ModalDialog>

    </>
  )
}

export default Checkout;