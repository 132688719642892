import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Button } from "react-bootstrap";
import Swal from 'sweetalert2';
import SweetAlert from 'sweetalert';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';

import { TextField, Grid, Select, InputAdornment } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import List from '@material-ui/core/List';
import ButtonMaterial from '@material-ui/core/Button';

import SearchIcon from '@material-ui/icons/Search';
import SubjectIcon from '@material-ui/icons/Subject';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from "@material-ui/icons/Close";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/pro-light-svg-icons";

import ModalDialog from '../../components/ModalDialog';
import GridProduct from '../../components/GridProduct';
import ProductCard from '../../components/ProductCard';

import PlugNotasService from '../../services/PlugNotas';
import InvoiceService from '../../services/Invoice';
import ScamberService from '../../services/Scamber';
import Domain from '../../services/Domain';
import Categorizacao from '../../services/Categorizacao';
import AnuncioService from '../../services/Anuncios';

import config from '../../configs/config.json';
import downloadBase64ToPDF from '../../utils/downloadBase64ToPDF';

export default function Invoice() {
  const anuncioService = new AnuncioService();

  const [registros, setRegistros] = useState([]);
  const [search, setSearch] = useState("");
  const [stores, setStores] = useState([]);
  const [selectedScamberOrigin, setSelectedScamberOrigin] = useState();
  const [category, setCategory] = useState("");
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState({ nome: "" });
  const [conditions, setConditions] = useState([]);
  const [condition, setCondition] = useState({ nome: "" });
  const [listSelectedCategorization, setListSelectedCategorization] = useState([]);
  const [listActiveCategorization, setListActiveCategorization] = useState([]);
  const [allCategorizationLabel, setAllCategorizationLabel] = useState("");
  const [listCategorization, setListCategorization] = useState([]);
  const [searchAds, setSearchAds] = useState();
  const [limit, setLimit] = useState(8);
  const [searchKey, setSearchKey] = useState("");
  const [deliveryType, setDeliveryType] = useState();
  const [storeUrl, setStoreUrl] = useState();
  const [points, setPoints] = useState();
  const [price, setPrice] = useState();
  const [selectedAds, setSelectedAds] = useState();
  const [selectedAdsToShow, setSelectedAdsToShow] = useState();

  const refModalAddInvoice = useRef();
  const refModalShowAds = useRef();
  const refModalDialogCategory = useRef();
  const refModalDialogSelectProduct = useRef();

  const { handleSubmit, register, errors, reset, setValue } = useForm();
  const { handleSubmit: handleSubmitSearchFilter, register: registerSearchFilter, reset: resetSearchFilter, errors: errorSearchFilter } = useForm();


  const handleOpenModalAddInvoice = () => {
    setSelectedScamberOrigin();
    reset({});

    refModalAddInvoice.current.openModal();
  }

  const handleScamebrOrigin = (e, store) => {
    setSelectedScamberOrigin(store);
  };

  const handleSyncInvoices = () => {
    SweetAlert({
      title: "Sincronizar Pedidos",
      text: `Confirmar sincronização pedidos?`,
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancelar", "Confirmar"],
    }).then(function (isConfirm) {
      if (isConfirm) {
        Swal.fire('Sincronizando Pedidos... Aguarde');
        Swal.showLoading();

        const invoiceService = new InvoiceService();
        invoiceService.syncAllInvoices().then((response) => {
          if (response.data && response.data.type === 'success') {
            Swal.fire({
              type: "success",
              html: 'Pedidos sincronizados com sucesso!',
              title: ""
            }).then(() => {
              handleSearchInvoice();
            });
          } else {
            Swal.fire({
              type: "info",
              html: response.data.message,
              title: "",
            });
          }
        }).catch((error) => {
          Swal.fire({
            type: "info",
            html: invoiceService.formatErrorMessage(error.response, 'HSAI'),
            title: "",
          });
        })
      }
    });
  }

  const handleSearchInvoice = () => {
    Swal.fire('Carregando Notas Fiscais ... Aguarde');
    const invoiceService = new InvoiceService();

    invoiceService.consultInvoice().then((response) => {
      setRegistros(response.data.payload);
      Swal.close();
    }).catch((err) => {
      Swal.fire({
        type: 'error',
        html: invoiceService.formatErrorMessage(err.response),
        title: '',
      });
    });
  };

  const handleShowProducts = ({ ads }) => {
    setSelectedAdsToShow(ads)

    refModalShowAds.current.openModal();
  }

  const consultInvoicePdf = ({ invoiceId }) => {
    const plugNotasService = new PlugNotasService();

    Swal.fire('Buscando pdf da NFe... Aguarde');
    Swal.showLoading();
    plugNotasService.consultInvoicePdf({ idNota: invoiceId }).then((response) => {
      downloadBase64ToPDF(response.data, 'nfe')
      Swal.close();
    }).catch(error => {
      Swal.close();
      Swal.fire({
        type: "info",
        html: plugNotasService.formatErrorMessage(error.response),
        title: "",
      });
    });
  }

  const clearFilter = () => {
    setSearch("");
    handleSearchInvoice();
  }

  const getHost = ({ productId }) => {

    if (config.nodejs.server.indexOf('localhost') !== -1) {
      return `https://d1.scamb.com.vc`
    } else if (config.nodejs.server.indexOf('/adm-dev') !== -1 && config.nodejs.server.indexOf('/adm-dev2') === -1) {
      return `https://d1.scamb.com.vc`
    } else if (config.nodejs.server.indexOf('/adm-dev2') !== -1) {
      return `https://d2.scamb.com.vc`
    } else if (config.nodejs.server.indexOf('apihml') !== -1 && config.nodejs.server.indexOf('apihml2') === -1) {
      return `https://h1.scamb.com.vc`
    } else if (config.nodejs.server.indexOf('apihml2') !== -1) {
      return `https://h2.scamb.com.vc`
    } else {
      return `https://scamb.com.vc`
    }

  }

  const handleAddInvoice = (data) => {
    const plugNotasService = new PlugNotasService();

    if (!selectedAds && selectedAds.length !== 0) {
      return Swal.fire({
        type: 'info',
        text: 'É nescessário escolher pelo menos um anúncio',
        title: '',
      });
    }

    const justIdsAds = [];
    selectedAds.map(ad => {
      return justIdsAds.push(ad._id);
    });

    Swal.fire('Criando Nota Fiscal... Aguarde');
    plugNotasService.createEntryInvoice({ selectedItemsIds: justIdsAds, scamberId: selectedScamberOrigin._id, type: data.type }).then((response) => {
      handleSearchInvoice();
      refModalAddInvoice.current.closeModal();

      Swal.fire({
        title: "",
        text: response.data.message,
        type: "success",
      });
    }).catch(err => {
      Swal.fire({
        type: "info",
        text: plugNotasService.formatErrorMessage(err.response),
        title: "",
      });
    })

  }

  const handleSearchStores = () => {
    const scamberService = new ScamberService();

    scamberService.listAllScamberWithStore().then((response) => {
      setStores(response.data.payload);
    })
  }

  const handleCategorizationBack = (oldCategorizationList, index) => {
    const listSelected = listSelectedCategorization.slice(0, index);
    setListSelectedCategorization(listSelected);

    setListActiveCategorization(oldCategorizationList);
  };

  const handleCategorization = (categorization) => {
    setCategory(categorization.nome);

    let listSelected;
    if (categorization.categorias && categorization.categorias.length > 0) {
      setListActiveCategorization(categorization.categorias);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else if (
      categorization.tipos_produtos &&
      categorization.tipos_produtos.length > 0
    ) {
      setListActiveCategorization(categorization.tipos_produtos);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else if (categorization.produtos && categorization.produtos.length > 0) {
      setListActiveCategorization(categorization.produtos);

      listSelected = listSelectedCategorization;
      listSelected.push({
        oldCategorizationList: listActiveCategorization,
        categorization: categorization,
      });
      setListSelectedCategorization(listSelected);
    } else {
      listSelected = listSelectedCategorization;
      const lastIndex = listSelected.length > 0 ? listSelected.length - 1 : 0;

      if (listSelected[lastIndex] && listSelected[lastIndex].lastLevel) {
        listSelected[lastIndex] = {
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
          lastLevel: true,
        };
        setListSelectedCategorization(listSelected);
      } else {
        listSelected.push({
          oldCategorizationList: listActiveCategorization,
          categorization: categorization,
          lastLevel: true,
        });
        setListSelectedCategorization(listSelected);
      }
    }
  };

  const onSelectCategorization = (categorizationlist) => {
    let selectedCategorizations = categorizationlist
      ? categorizationlist
      : listSelectedCategorization;

    let categorizationIdsSelected = selectedCategorizations.map(
      (selectedCategorization) => {
        return selectedCategorization.categorization._id;
      }
    );
  }

  const hasChindren = (categorization) => {
    if (categorization.categorias && categorization.categorias.length > 0) {
      return true;
    } else if (
      categorization.tipos_produtos &&
      categorization.tipos_produtos.length > 0
    ) {
      return true;
    } else if (categorization.produtos && categorization.produtos.length > 0) {
      return true;
    }

    return false;
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start'
  });

  const handleCondition = (e, condition) => {
    setCondition(condition);
  };

  const handleBrand = (e, brand) => {
    setBrand(brand);
  };

  const searchAnuncio = ({ limit, key, deliveryType, points, price, storeUrl }) => {
    const anuncioService = new AnuncioService();

    if (key) {
      if (key.indexOf('&') !== -1) key = key.replace(/&/g, 'ampersand');
    }

    anuncioService.findAdsByUserSearch({
      searchString: key,
      limit: limit,
      brand: brand ? brand._id : null,
      category: listSelectedCategorization.length > 0 ? listSelectedCategorization[listSelectedCategorization.length - 1].categorization._id : null,
      condition: condition ? condition._id : null,
      deliveryType: deliveryType ? deliveryType : null,
      points: points ? points : null,
      price: price ? price : null,
      website: 'scamb',
      storeUrl: storeUrl ? storeUrl : null,
      statusAd: 'ativo'
    }).then(response => {
      setSearchAds(response.data.payload.ad);
    }).catch(err => {
      Swal.fire({
        type: "info",
        html: anuncioService.formatErrorMessage(err.response),
        title: "",
      });
    })
  }

  const onSearch = (data) => {
    setSearchKey(data.filterby);
    setDeliveryType(data.frete_type);
    setPoints(`${data.pontosMinimos}-${data.pontosMaximos}`);
    setPrice(data.order);

    searchAnuncio({
      limit: limit,
      key: data.filterby,
      deliveryType: data.frete_type,
      points: `${data.pontosMinimos}-${data.pontosMaximos}`,
      price: data.order,
      storeUrl: storeUrl,
    });
  }

  const loadMoreAds = () => {
    const newLimit = limit + 8;
    setLimit(newLimit);

    searchAnuncio({
      limit: newLimit,
      key: searchKey,
      deliveryType: deliveryType ? deliveryType : null,
      points: points ? points : null,
      price: price ? price : null,
      storeUrl: storeUrl,
    });
  }

  const addAnuncios = (e, anuncio) => {
    e.preventDefault();

    if (selectedAds) {
      const existAnuncio = selectedAds.find((a) => a._id === anuncio._id);

      if (!existAnuncio) {
        setSelectedAds([...selectedAds, anuncio]);
      }
    } else {
      setSelectedAds([anuncio]);
    }
  }

  const removeAnuncios = (e, anuncio) => {
    e.preventDefault();

    const excludesAnuncio = selectedAds.filter((a) => a._id !== anuncio._id);
    setSelectedAds(excludesAnuncio);
  }

  const onCleanFilter = () => {
    resetSearchFilter({})
    setBrand([]);
    setCondition([]);
    setListSelectedCategorization([]);
    setLimit(8);
    setSearchKey("");
    setDeliveryType();
    setPoints();
    setPrice();

    searchAnuncio({ limit: 8, key: "", storeUrl: storeUrl })
  }

  const openSelectProductModal = ({ scamber }) => {
    setBrand([]);
    setCondition([]);
    setListSelectedCategorization([]);
    resetSearchFilter({});
    setLimit(8);
    setSearchKey("");
    setDeliveryType();
    setPoints();
    setPrice();
    setStoreUrl(scamber.minha_loja?.url);

    searchAnuncio({ limit: limit, key: "", storeUrl: scamber.minha_loja?.url })
    refModalDialogSelectProduct.current.openModal();
  }

  const openCategorizationModal = () => {
    setListActiveCategorization(listCategorization);
    setListSelectedCategorization([]);
    setAllCategorizationLabel("");

    refModalDialogCategory.current.openModal();
  };

  const loadInfo = () => {
    const categorization = new Categorizacao();
    const domain = new Domain();

    categorization.list().then((response) => {
      if (response.status === 200) {
        setListCategorization(response.data.payload);
        setListActiveCategorization(response.data.payload);
      }
    }).catch((error) =>
      categorization.formatErrorMessage(error.response, 'CALICT'),
    );

    domain.listBrands().then((response) => {
      if (response.status === 200) {
        setBrands(response.data.payload);
      }
    }).catch((error) =>
      domain.formatErrorMessage(error.response, 'CALIBDO'),
    );

    domain.listConditions().then((response) => {
      if (response.status === 200) {
        setConditions(response.data.payload);
      }
    }).catch((error) =>
      domain.formatErrorMessage(error.response, 'CALICDO'),
    );
  };

  const handleInitialValue = useCallback(() => {
    handleSearchStores();
    handleSearchInvoice();
  }, []);

  useEffect(() => {
    handleInitialValue();
    loadInfo();
  }, [handleInitialValue]);

  return (
    <>
      <main role="main" id="view_account-details">
        <div className="_fluid-container">

          <div className="_page-section">
            <div className="icon">
              <SubjectIcon />
            </div>
            <div className="title">
              Notas fiscais
            </div>
          </div>

          <div className="_wrap-filter-activity">
            <div className="_filter-activity">
              {/* <div className="_form _search" >
                <div className="form-group search">
                  <TextField
                    id="filterShowcase"
                    label="Filtrar"
                    variant="outlined"
                    size={"small"}
                    fullWidth
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ style: { height: '39px' } }}
                  />

                  <button onClick={() => handleSearchInvoice()}>
                    <SearchIcon></SearchIcon>
                  </button>
                </div>
              </div> */}
              {/* <div className="excel-div">
                <button onClick={(e) => clearFilter(e)} className="excel-button">Limpar Filtros</button>
              </div> */}
              <div className="excel-div">
                <button className="excel-button" onClick={() => handleOpenModalAddInvoice()}>Criar Nota Fiscal</button>
              </div>
              <div className="excel-div">
                <button className="excel-button" onClick={() => handleSyncInvoices()}>Sincronizar Pedidos</button>
              </div>
            </div>
          </div>

          <table className="table table-striped">
            <thead>
              <tr>
                <th scope={"col"}>Nota Fiscal</th>
                <th scope={"col"}>Destinatário</th>
                <th scope={"col"}>Data inclusão</th>
                <th scope={"col"}>Tipo</th>
                <th scope={"col"}>Anuncios</th>
                <th scope={"col"}>Gerar PDF</th>
              </tr>
            </thead>
            <tbody>
              {registros.length > 0 ? registros.map((registro) => {
                return (
                  <tr key={registro._id}>
                    <td>{registro._id}</td>
                    <td>{registro?.destinatario?.email}</td>
                    <td>{format(new Date(registro.data_inclusao), 'dd/MM/yyyy')}</td>
                    <td>{registro.tipo}</td>
                    <td>
                      <Button
                        className={"btn-step btn-edit-table"}
                        color="primary"
                        autoFocus
                        style={{ height: 45 }}
                        onClick={() => handleShowProducts({ ads: registro.anuncios })}
                      >
                        Detalhes
                      </Button>
                    </td>
                    <td>
                      <Button
                        className={"btn-step btn-edit-table"}
                        color="primary"
                        autoFocus
                        style={{ height: 45 }}
                        onClick={() => consultInvoicePdf({ invoiceId: registro.documents.id })}
                      >
                        Gerar pdf
                      </Button>
                    </td>
                  </tr>
                )
              }) :
                <>
                </>
              }
            </tbody>
          </table>
        </div>
      </main>

      <ModalDialog
        ref={refModalAddInvoice}
        dialogTitle="Incluir Nota Fiscal"
        className="GridProduct"
        onCloseModal={
          <button
            type="button"
            className="btn"
            onClick={() => refModalAddInvoice.current.closeModal()}
          >
            {<CloseIcon />}
          </button>
        }>
        <div className="_wrap-form">
          <form className="_form" onSubmit={handleSubmit(handleAddInvoice)}>
            <h1 className="_content-title">Preencha as informações</h1>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  value={selectedScamberOrigin || null}
                  filterOptions={filterOptions}
                  options={stores}
                  includeInputInList
                  freeSolo
                  getOptionLabel={(option) => option.minha_loja.titulo}
                  getOptionSelected={(option) => option._id}
                  fullWidth
                  onChange={(e, value) => handleScamebrOrigin(e, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Scamber de Origem"
                      name="scamber_origem"
                      variant="outlined"
                      size={"small"}
                      error={!!errors.scamber_origem}
                      inputRef={register({ required: true })}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>

              <Grid item md={12} xs={12} style={{ marginBottom: '5px' }}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                    Tipo
                  </InputLabel>
                  <Select
                    native
                    label="Tipo"
                    error={!!errors.type}
                    inputRef={register({
                      required: true
                    })}
                    inputProps={{ name: "type" }}
                  >
                    <option value=""></option>
                    <option value="ENTRADA">Entrada</option>
                    <option value="SAIDA">Saída</option>
                  </Select>
                </FormControl>
              </Grid>

              {selectedScamberOrigin && (
                <Grid item xs={12}>
                  <h1 className="_content-title">Seleção de Produtos</h1>
                  <Button onClick={() => openSelectProductModal({ scamber: selectedScamberOrigin })}>
                    Selecionar Produtos
                  </Button>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  className="btn-step"
                  style={{ height: 35, marginBottom: '20px' }}
                >
                  Concluir
                </Button>
              </Grid>

            </Grid>
          </form>
        </div>
      </ModalDialog>

      <ModalDialog
        dialogTitle="Seleção de Produtos"
        className="_modal-category GridProducts"
        maxWidth="lg"
        btnTitle="Aplicar"
        ref={refModalDialogSelectProduct}
      >
        <div className="select-product-container">
          <div className="search-products-container">
            <div className="_wrap-form" style={{ marginBottom: '20px', marginTop: '20px' }}>
              <form className="_form" onSubmit={handleSubmitSearchFilter(onSearch)}>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-add-title"
                      label="Filtrar por"
                      type="text"
                      variant="outlined"
                      size={"small"}
                      name="filterby"
                      fullWidth
                      inputRef={registerSearchFilter({ required: false })}
                      inputProps={{ maxLength: 40 }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                        Ordenar por
                      </InputLabel>
                      <Select
                        native
                        label="Ordernar por"
                        inputRef={registerSearchFilter({ required: false })}
                        inputProps={{ name: "order" }}
                      >
                        <option value=""></option>
                        <option value={-1}>Maior Preço</option>
                        <option value={1}>Menor Preço</option>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-category"
                      label="Categoria"
                      type="text"
                      variant="outlined"
                      size={"small"}
                      name="add_category"
                      value={allCategorizationLabel}
                      fullWidth
                      error={!!errorSearchFilter.add_category}
                      inputRef={registerSearchFilter({ required: false })}
                      InputLabelProps={{ shrink: true }}
                      onClick={() => openCategorizationModal()}
                      inputProps={{
                        endadornment: (
                          <InputAdornment position="end">
                            <FontAwesomeIcon
                              icon={faChevronRight}
                            ></FontAwesomeIcon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <Autocomplete
                      filterOptions={filterOptions}
                      id="outlined-brand"
                      options={brands}
                      includeInputInList
                      freeSolo
                      getOptionLabel={(option) => option.nome}
                      getOptionSelected={(option) => option._id}
                      fullWidth
                      value={brand}
                      onChange={(e, value) => handleBrand(e, value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Marca"
                          name="brand"
                          variant="outlined"
                          size={"small"}
                          error={!!errorSearchFilter.brand}
                          inputRef={registerSearchFilter({
                            required: false,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <Autocomplete
                      id="outlined-condition"
                      autoComplete={true}
                      options={conditions}
                      getOptionLabel={(option) => option.nome}
                      getOptionSelected={(option) => option._id}
                      size="small"
                      fullWidth
                      value={condition}
                      onChange={(e, value) => handleCondition(e, value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Condição"
                          name="add_condition"
                          variant="outlined"
                          size={"small"}
                          error={!!errorSearchFilter.add_condition}
                          inputRef={registerSearchFilter({
                            required: false,
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-outlined-label">
                        Tipo de Frete
                      </InputLabel>
                      <Select
                        native
                        label="Tipo de Frete"
                        inputRef={registerSearchFilter({
                          required: false
                        })}
                        inputProps={{
                          name: "frete_type",
                        }}
                      >
                        <option value=""></option>
                        <option value={"operador_logistico"}>Correios</option>
                        <option value={"entrega_maos"}>Entrega em mãos</option>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-login"
                      label="Pontos: Mínimo"
                      type="number"
                      variant="outlined"
                      size="small"
                      name="pontosMinimos"
                      defaultValue={0}
                      fullWidth
                      inputRef={registerSearchFilter({ required: true })}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <TextField
                      id="outlined-login"
                      label="Pontos: Máximo"
                      type="number"
                      variant="outlined"
                      size="small"
                      name="pontosMaximos"
                      defaultValue={9999}
                      fullWidth
                      inputRef={registerSearchFilter({ required: true })}
                    />
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <Button type='submit' style={{ marginTop: '3px' }}>
                      Aplicar
                    </Button>
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <Button color="secondary" style={{ marginTop: '3px' }} onClick={() => onCleanFilter()}>
                      Limpar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>

            <Grid container spacing={2}>
              {searchAds && searchAds.map((ad, index) => (
                <Grid item lg={3} md={4} sm={4} xs={6} key={index}>
                  <GridProduct
                    link={ad.url_encurtada}
                    image={
                      anuncioService.loadImage(ad.fotos[0])
                        ? anuncioService.loadImage(ad.fotos[0])
                        : ""
                    }
                    weight={ad.tamanho_exibicao?.nome}
                    title={ad.titulo}
                    discount={
                      ad.pontos_de
                        ? ad.pontos_de.$numberDecimal
                        : ""
                    }
                    price={
                      ad.pontos_por
                        ? ad.pontos_por.$numberDecimal
                        : ""
                    }
                    type={ad.tipo_anuncio}
                    cashback={ad.cashback}
                    cashDiscount={ad.valor_de}
                    cashPrice={ad.valor_por}
                    website='scamb'
                    onClick={(e) => addAnuncios(e, ad)}
                  />
                </Grid>
              ))}

            </Grid>

            <div className="_wrap-load-more">
              {limit <= searchAds?.length ? (<button type="submit" onClick={() => loadMoreAds()} className="btn btn-red">Carregar mais</button>) : null}
            </div>
          </div>

          {selectedAds?.length > 0 && (
            <div className="alredy-select-container">
              <div className="_title-select-product">Produtos já selecionados</div>

              <List className="alredy-select-list">
                {selectedAds && selectedAds.map((ad, index) => (
                  <ProductCard
                    key={ad._id}
                    image={anuncioService.loadImage(ad.fotos[0])}
                    title={ad.titulo}
                    // subtitle={`R$${ad?.taxa_scamb?.$numberDecimal || 0}`}
                    url={ad.url_encurtada}
                  >
                    <ButtonMaterial
                      endIcon={<DeleteIcon color="secondary" />}
                      onClick={(e) => { removeAnuncios(e, ad) }}
                    />
                  </ProductCard>
                ))}
              </List>
            </div>
          )}
        </div>
      </ModalDialog>

      <ModalDialog
        customMethod={onSelectCategorization}
        dialogTitle="Categorias"
        btnTitle="Aplicar"
        className="_modal-category"
        maxWidth="xs"
        ref={refModalDialogCategory}
      >
        <span className="_content">
          <a href="#!" className="tag-selected-radio">
            {listSelectedCategorization.map(
              (selectedCategorization, index) => {
                if (index === 0) {
                  return (
                    <div
                      onClick={() =>
                        handleCategorizationBack(
                          selectedCategorization.oldCategorizationList,
                          index
                        )
                      }
                      key={index}
                      className="_title-selected _only"
                    >
                      <FontAwesomeIcon
                        className="icon"
                        icon={faChevronLeft}
                      ></FontAwesomeIcon>
                      <span class="_text">

                        {selectedCategorization.categorization.nome}
                      </span>
                    </div>
                  );
                } else {
                  return (
                    <div
                      onClick={() =>
                        handleCategorizationBack(
                          selectedCategorization.oldCategorizationList,
                          index
                        )
                      }
                      key={index}
                      className="_title-selected _only _multiple"
                    >
                      <span class="_text">

                        {selectedCategorization.categorization.nome}
                      </span>
                    </div>
                  );
                }
              }
            )}
          </a>

          <ul id="drilldown">
            {listActiveCategorization.map((categorization, index) => (
              <li key={index}>
                <label>
                  <input
                    type="radio"
                    name="select_category_lvl_1"
                    checked={
                      category === categorization.nome ? "checked" : ""
                    }
                    onChange={() => handleCategorization(categorization)}
                  />
                  <span className="_name">{categorization.nome}</span>
                  {hasChindren(categorization) ? (
                    <div className="icon">
                      <FontAwesomeIcon
                        icon={faChevronRight}
                      ></FontAwesomeIcon>
                    </div>
                  ) : (
                    <></>
                  )}
                </label>
              </li>
            ))}
          </ul>
        </span>
      </ModalDialog>

      <ModalDialog
        ref={refModalShowAds}
        dialogTitle="Produtos"
        onCloseModal={
          <button
            type="button"
            className="btn"
            onClick={() => refModalShowAds.current.closeModal()}
          >
            <CloseIcon />
          </button>
        }
      >
        <div className="_wrap-form">
          {selectedAdsToShow && selectedAdsToShow.map(item => (
            <div key={item._id}>
              <p style={{ paddingTop: '15px', marginBottom: 0 }}><strong>Produto: </strong><a href={getHost(item._id)} target="_black">{item.titulo}</a></p>
              <p style={{ marginBottom: 0 }}><strong>Descrição: </strong> {item.descricao}</p>
              <p style={{ marginBottom: 0 }}><strong>Valor Pontos: </strong> {item.pontos_por.$numberDecimal}</p>
              <p style={{ paddingBottom: '15px', marginBottom: 0 }}><strong>Valor Taxa: </strong> {item?.taxa_scamb.$numberDecimal || 0}</p>
            </div>
          ))}
        </div>
      </ModalDialog>
    </>
  )
}